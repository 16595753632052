<template>
  <div :class="$style.Host">
    <div :class="$style.LeftButtons"></div>
    <div :class="$style.Title">
      {{ title }}
    </div>
    <div :class="$style.RightButtons">
      <div :class="$style.DoneButton" @click="$emit('close')">Done</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },

  emits: ["close"],
};
</script>

<style module lang="scss">
@import "@/styles/layout";

.Host {
  @include layout-horizontal;

  border-bottom: 1px solid #e1e1e1;
  padding: 10px;
}

.Title {
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  user-select: none;
}

.LeftButtons {
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 1;
}

.RightButtons {
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: right;
}

.DoneButton {
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
  padding: 0 10px;
}
</style>
