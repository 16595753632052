<template>
  <div :class="$style.Host">
    <router-view :class="$style.View" />
  </div>
</template>

<script>
export default {};
</script>

<style module lang="scss">
@import "@/styles/layout";

.Host {
  @include layout-vertical;

  /* 10 pixels to allow the the little bar on the bottom of iOS */
  bottom: 10px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.View {
  flex-grow: 1;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
</style>

<style lang="scss">
@import "@/styles/fonts";

* {
  color: inherit;
  font: inherit;
  margin: 0;
  padding: 0;
}

html body {
  @include fonts-clear;
}

[hidden] {
  display: none !important;
}
</style>
