<template>
  <div :class="$style.Host">
    <TaskList :class="$style.TaskList" />

    <div :class="$style.Footer">
      <TaskBorder gray @click="() => $refs.addNewModal.open()">
        <div :class="$style.AddNew">Add New</div>
      </TaskBorder>
    </div>

    <ModalFrame ref="addNewModal" title="Add New">
      <CreateTaskForm @submit="() => $refs.addNewModal.close()" />
      <div :class="$style.Spacer" />
    </ModalFrame>
  </div>
</template>

<script>
import CreateTaskForm from "@/components/CreateTaskForm";
import ModalFrame from "@/components/ModalFrame";
import TaskBorder from "@/components/TaskBorder";
import TaskList from "@/components/TaskList";

export default {
  components: { CreateTaskForm, ModalFrame, TaskBorder, TaskList },
};
</script>

<style module lang="scss">
@import "@/styles/layout";

.Host {
  @include layout-vertical;

  position: relative;
}

.TaskList {
  flex-grow: 1;
}

.Footer {
  border-top: 1px solid rgb(160, 160, 160);
  flex-shrink: 0;
  padding: 10px;
}

.AddNew {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  padding: 10px;
  text-align: center;
}

.Spacer {
  height: 10px;
}
</style>
