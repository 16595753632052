<template>
  <div :class="$style.Host">
    <TaskBorder :score="score_" @click="$refs.details.open()">
      <div :class="$style.Task">
        <div :class="$style.TaskLabel">{{ task_.metadata.title }}</div>
        <div :class="$style.TaskDetails">due {{ describe_(dueDate_) }}</div>
      </div>
    </TaskBorder>

    <ModalFrame ref="details" title="Task Details">
      <TaskDetails :id="id" @save="$refs.details.close()" />
    </ModalFrame>
  </div>
</template>

<script>
import { humanize } from "human-readable-duration";

import ModalFrame from "@/components/ModalFrame";
import TaskBorder from "@/components/TaskBorder";
import TaskDetails from "@/components/TaskDetails";

import store from "@/store";

export default {
  components: { ModalFrame, TaskBorder, TaskDetails },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  computed: {
    task_() {
      return store.state.tasks[this.id];
    },

    dueDate_() {
      return store.getters.taskDueDates[this.id];
    },

    score_() {
      return store.getters.taskScores[this.id];
    },
  },

  methods: {
    describe_(date) {
      return humanize().translate(date);
    },
  },
};
</script>

<style module lang="scss">
@import "@/styles/layout";

.Host {
}

.Task {
  @include layout-horizontal;

  font-size: 14px;
  line-height: 22px;
  user-select: none;
}

.TaskLabel {
  flex-grow: 1;
  font-weight: 700;
  margin: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.TaskDetails {
  flex-shrink: 0;
  text-align: right;
  margin: 10px 10px 10px 0;
}
</style>
