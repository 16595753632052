import * as deepcopy from "deepcopy";
import { createStore } from "vuex";
import { v4 as uuidv4 } from "uuid";

import { loadDatabase, saveDatabase } from "@/storage/database";

/// Task Actions

function createTask({ commit }, { metadata, history, scheduling }) {
  const task = {
    id: uuidv4(),

    metadata: {
      title: metadata.title,
      dateCreated: new Date(),
      tags: [],
    },

    history: {
      lastOccurence: history.lastOccurence || null,
    },

    scheduling: {
      type: "periodic",
      period: scheduling.period,
    },
  };

  commit("setTask", { id: task.id, task });
  return task;
}

function deleteTask({ state, commit }, { id }) {
  if (!state.tasks[id]) {
    return false;
  }

  commit("setTask", { id, task: null });
  return true;
}

function completeTask({ state, commit }, { id }) {
  const task = deepcopy(state.tasks[id]);
  if (!task) {
    return false;
  }

  task.history.lastOccurence = new Date();
  commit("setTask", { id, task });
  return true;
}

function setTaskTitle({ state, commit }, { id, title }) {
  const task = deepcopy(state.tasks[id]);
  if (!task) {
    return false;
  }

  task.metadata.title = title;
  commit("setTask", { id, task });
  return true;
}

/// Task Getters

function taskDueDates(state) {
  function getTaskDueDate(task) {
    if (task.scheduling.type == "periodic" && task.history.lastOccurence) {
      return new Date(
        task.history.lastOccurence.getTime() + task.scheduling.period
      );
    }

    // TODO: handle fixed scheduling.

    return new Date();
  }

  const dueDates = {};
  for (const task of Object.values(state.tasks)) {
    dueDates[task.id] = getTaskDueDate(task);
  }
  return dueDates;
}

function taskScores(state) {
  function getTaskScore(task) {
    if (task.scheduling.type == "periodic" && task.history.lastOccurence) {
      return (
        (state.now.getTime() - task.history.lastOccurence.getTime()) /
        task.scheduling.period
      );
    }

    // TODO: handle fixed scheduling.

    return 1;
  }

  const scores = {};
  for (const task of Object.values(state.tasks)) {
    scores[task.id] = getTaskScore(task);
  }
  return scores;
}

/// Tag Actions

function createTag({ commit }, { label, color }) {
  const tag = {
    id: uuidv4(),

    metadata: {
      dateCreated: new Date(),
      label,
      color,
    },
  };

  commit("setTag", { id: tag.id, tag });
  return tag;
}

function deleteTag({ state, commit, dispatch }, { id }) {
  if (!state.tags[id]) {
    return false;
  }

  for (const taskId of Object.keys(state.tasks)) {
    dispatch("removeTagFromTask", { taskId, tagId: id });
  }

  commit("setTag", { id, tag: null });
  return true;
}

function setTagColor({ state, commit }, { id, color }) {
  const tag = deepcopy(state.tags[id]);
  if (!tag) {
    return false;
  }

  tag.metadata.color = color;
  commit("setTag", { id, tag });
  return true;
}

function addTagToTask({ state, commit }, { taskId, tagId }) {
  if (!state.tags[tagId]) {
    return false;
  }

  const task = deepcopy(state.tasks[taskId]);
  if (!task || task.metadata.tags.indexOf(tagId) >= 0) {
    return false;
  }

  task.metadata.tags.push(tagId);
  commit("setTask", { id: taskId, task });
  return true;
}

function removeTagFromTask({ state, commit }, { taskId, tagId }) {
  const task = deepcopy(state.tasks[taskId]);
  if (!task) {
    return false;
  }

  const index = task.metadata.tags.indexOf(tagId);
  if (index == -1) {
    return false;
  }

  task.metadata.tags.splice(index, 1);
  commit("setTask", { id: taskId, task });
  return true;
}

/// Store

export default createStore({
  state: {
    now: new Date(),
    tasks: loadDatabase("tasks"),
    tags: loadDatabase("tags"),
  },

  mutations: {
    updateNow(state) {
      state.now = new Date();
    },

    setTask(state, { id, task }) {
      if (task) {
        state.tasks[id] = task;
      } else {
        delete state.tasks[id];
      }
      saveDatabase("tasks", state.tasks);
    },

    setTag(state, { id, tag }) {
      if (tag) {
        state.tags[id] = tag;
      } else {
        delete state.tags[id];
      }
      saveDatabase("tags", state.tags);
    },
  },

  getters: {
    // Tasks
    taskDueDates,
    taskScores,
  },

  actions: {
    updateNowEvery({ commit }, { milliseconds }) {
      return window.setInterval(() => {
        commit("updateNow");
      }, milliseconds);
    },

    // Tasks
    createTask,
    deleteTask,
    completeTask,
    setTaskTitle,

    // Tags
    createTag,
    deleteTag,
    setTagColor,
    addTagToTask,
    removeTagFromTask,
  },
});
