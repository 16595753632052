<template>
  <div :class="$style.Host">
    <input
      type="text"
      v-model="form_.label"
      :class="$style.Input"
      placeholder="Label"
    />
    <select :class="$style.Select" v-model="form_.mode">
      <option value="periodic">Periodic</option>
      <option value="date_window">Date Window</option>
    </select>

    <template v-if="form_.mode == 'periodic'">
      <input
        type="number"
        min="0"
        v-model="form_.periodic.period"
        :class="$style.Input"
        placeholder="Period (days)"
      />
      <DatePicker
        :class="$style.DateInput"
        v-model="form_.periodic.lastOccurence"
      />
    </template>

    <template v-if="form_.mode == 'date_window'">
      <DatePicker
        :class="$style.DateInput"
        range
        v-model="form_.dateWindow.range"
      />
    </template>

    <div :class="$style.Submit" :disabled="disabled_" @click="submit_">
      Create Task
    </div>
  </div>
</template>

<script>
import DatePicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";

import store from "@/store";

export default {
  components: { DatePicker },

  data() {
    return {
      form_: {
        label: null,
        mode: "periodic",
        periodic: {
          period: null,
          lastOccurence: null,
        },
        dateWindow: {
          range: null,
        },
      },
    };
  },

  computed: {
    disabled_() {
      return (
        !this.form_.label ||
        (this.form_.mode == "periodic" && !this.form_.periodic.period) ||
        (this.form_.mode == "date_window" && !this.form_.dateWindow.range)
      );
    },
  },

  methods: {
    submit_() {
      if (this.disabled_) {
        return;
      }

      const request = {
        metadata: {
          title: this.form_.label,
        },
        scheduling: {},
        history: {},
      };

      if (this.form_.mode == "periodic") {
        request.scheduling.type = "periodic";
        request.scheduling.period =
          this.form_.periodic.period * 1000 * 60 * 60 * 24;
        request.history.lastOccurence = this.form_.periodic.lastOccurence;
      } else if (this.form_.mode == "date_window") {
        request.scheduling.type = "fixed";
        request.scheduling.start = this.form_.dateWindow.range[0];
        request.scheduling.end = this.form_.dateWindow.range[1];
      } else {
        throw Error(`Unknown mode ${this.form_.mode}`);
      }

      store.dispatch("createTask", request).then(() => {
        this.form_.label = null;
        this.form_.period = null;
        this.form_.lastOccurence = null;
        this.$emit("submit");
      });
    },
  },
};
</script>

<style module lang="scss">
.Host {
  margin: 20px;
}

.Input {
  margin-bottom: 10px;
  border-radius: 2px;
  border: 1px solid rgb(160, 160, 160);
  box-sizing: border-box;
  font-size: 14px;
  line-height: 22px;
  outline: none;
  padding: 6px 10px;
  width: 100%;

  &:hover,
  &:active {
    border-color: darken(rgb(160, 160, 160), 20%);
  }

  &:focus {
    border-color: #03a9f4;
  }
}

.DateInput {
  margin-bottom: 10px;
}

.Select {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 22px;
}

.Submit {
  border-radius: 2px;
  border: 1px solid rgb(160, 160, 160);
  box-sizing: border-box;
  font-size: 14px;
  line-height: 22px;
  padding: 6px 10px;
  text-align: center;
  width: 100%;

  &:hover,
  &:active {
    border-color: darken(rgb(160, 160, 160), 20%);
  }

  &[disabled="true"] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
</style>
