<template>
  <div :class="$style.Host">
    <div :class="$style.Header">
      <TagList
        :class="$style.TagList"
        :tagIds="attachedTags_"
        controls
        @remove="({ tagId }) => remove(tagId)"
      />

      <input
        :class="$style.TagInput"
        type="name"
        v-model.trim="search"
        placeholder="Search / Create new"
        @keydown.enter="submit_"
      />
    </div>

    <div :class="$style.Options">
      <template v-for="tag of searchResults_" :key="tag.id">
        <div :class="$style.TagOption" @click="add(tag.id)">
          <Tag :label="tag.metadata.label" :color="tag.metadata.color" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import distinctColors from "distinct-colors";
import randomItem from "random-item";

import Tag from "@/components/Tag";
import TagList from "@/components/TagList";

import store from "@/store";

const kColors = distinctColors({
  count: 15,
  lightMin: 50,
}).map((color) => color.hex());

export default {
  components: { Tag, TagList },

  props: {
    taskId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      search: null,
    };
  },

  computed: {
    allTags_() {
      return Object.values(store.state.tags);
    },

    attachedTags_() {
      return store.state.tasks[this.taskId].metadata.tags;
    },

    searchResults_() {
      if (!this.search) {
        return this.allTags_;
      }

      return this.allTags_.filter(
        (tag) => tag.metadata.label.indexOf(this.search) >= 0
      );
    },
  },

  methods: {
    remove(id) {
      store.dispatch("removeTagFromTask", { taskId: this.taskId, tagId: id });
    },

    add(id) {
      this.search = "";
      store.dispatch("addTagToTask", { taskId: this.taskId, tagId: id });
    },

    submit_() {
      if (!this.search) {
        return;
      }

      const existingTag = this.allTags_.find(
        (tag) => tag.metadata.label == this.search
      );
      if (existingTag) {
        this.add(existingTag.id);
      } else {
        store
          .dispatch("createTag", {
            label: this.search,
            color: randomItem(kColors),
          })
          .then((tag) => {
            this.add(tag.id);
          });
      }

      this.search = null;
    },
  },
};
</script>

<style module lang="scss">
@import "@/styles/layout";

.Host {
  @include layout-vertical;

  height: 100%;
}

.Header {
  flex-shrink: 0;
}

.Options {
  flex-grow: 1;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.TagList {
  padding: 4px;
}

.TagOption {
  @include layout-horizontal;

  padding: 10px;
  border-bottom: 1px solid #e1e1e1;
}

.TagInput {
  background: #e1e1e1;
  border: none;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 22px;
  outline: none;
  padding: 8px;
  width: 100%;
}
</style>
