<template>
  <div :class="$style.Host">
    <template v-for="id of sortedTaskIds_" :key="id">
      <TaskListItem :id="id" :class="$style.Task" />
    </template>
  </div>
</template>

<script>
import TaskListItem from "@/components/TaskListItem";

import store from "@/store";

export default {
  components: { TaskListItem },

  computed: {
    sortedTaskIds_() {
      const ids = Object.keys(store.state.tasks);
      ids.sort((a, b) => {
        const scoreA = store.getters.taskScores[a];
        const scoreB = store.getters.taskScores[b];
        return scoreA == scoreB ? 0 : scoreA < scoreB ? 1 : -1;
      });
      return ids;
    },
  },
};
</script>

<style module lang="scss">
@import "@/styles/layout";

.Host {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.Task {
  margin: 10px;
}
</style>
