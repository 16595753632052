export default [
  {
    tag: "0.0.0",
    empty: {},
  },
  {
    tag: "0.0.1",
    empty: {},
    migration(previousVersion) {
      const database = {};
      for (const [id, tag] of Object.entries(previousVersion)) {
        database[id] = {
          id,
          metadata: {
            label: tag.label,
            color: tag.color,
            dateCreated: tag.dateCreated,
          },
        };
      }
      return database;
    },
  },
];
