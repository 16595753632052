<template>
  <div :class="$style.Host">
    <div :class="$style.Title">{{ task_.metadata.title }}</div>
    <div :class="$style.History">{{ historyText_ }}</div>

    <template v-if="task_.metadata.tags.length">
      <TagList :tag-ids="task_.metadata.tags" @click="$refs.tagsForm.open()" />
    </template>
    <template v-else>
      <div :class="$style.Button" @click="$refs.tagsForm.open()">Add Tags</div>
    </template>

    <div :class="$style.Button" @click="completeTask_()">Complete Task</div>

    <ModalFrame ref="tagsForm" title="Tags" full>
      <TagForm :task-id="id" />
    </ModalFrame>
  </div>
</template>

<script>
import { humanize } from "human-readable-duration";

import ModalFrame from "@/components/ModalFrame";
import TagForm from "@/components/TagForm";
import TagList from "@/components/TagList";

import store from "@/store";

export default {
  components: { ModalFrame, TagForm, TagList },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  emits: ["save"],

  computed: {
    task_() {
      return store.state.tasks[this.id];
    },

    historyText_() {
      return this.task_.history.lastOccurence
        ? "Last completed " +
            humanize().translate(this.task_.history.lastOccurence)
        : "Not yet completed";
    },
  },

  methods: {
    completeTask_() {
      store.dispatch("completeTask", { id: this.id }).then(() => {
        this.$emit("save");
      });
    },
  },
};
</script>

<style module lang="scss">
.Host {
  padding: 10px 10px 20px 10px;
}

.Title {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  user-select: none;
}

.History {
  font-size: 14px;
  line-height: 22px;
  user-select: none;
}

.Button {
  border-radius: 2px;
  border: 1px solid rgb(160, 160, 160);
  box-sizing: border-box;
  font-size: 14px;
  line-height: 22px;
  margin: 10px 0;
  padding: 6px 10px;
  text-align: center;
  width: 100%;

  &:hover,
  &:active {
    border-color: darken(rgb(160, 160, 160), 20%);
  }

  &[disabled="true"] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
</style>
