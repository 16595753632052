<template>
  <teleport to="body">
    <div :class="[$style.Host, ...hostClasses_]">
      <div :class="$style.Empty" @click="close" />
      <div :class="$style.Modal" ref="modal">
        <ModalHeader :title="title" @close="close" />
        <slot />
      </div>
    </div>
  </teleport>
</template>

<script>
import ModalHeader from "@/components/ModalHeader";

export default {
  components: { ModalHeader },

  props: {
    title: {
      type: String,
      default: "",
    },

    full: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["close", "open"],

  data() {
    return {
      hostClasses_: new Set(),
    };
  },

  watch: {
    full: {
      immediate: true,
      handler() {
        if (this.full) {
          this.hostClasses_.add(this.$style.Host_Full);
        } else {
          this.hostClasses_.delete(this.$style.Host_Full);
        }
      },
    },
  },

  methods: {
    open() {
      this.hostClasses_.add(this.$style.Host_Visible);
      setTimeout(() => {
        this.hostClasses_.add(this.$style.Host_Open);
        setTimeout(() => {
          this.$emit("open");
        }, 300);
      }, 10);
    },

    close() {
      this.hostClasses_.delete(this.$style.Host_Open);
      setTimeout(() => {
        this.hostClasses_.delete(this.$style.Host_Visible);
        this.$emit("close");
      }, 300);
    },
  },
};
</script>

<style module lang="scss">
@import "@/styles/layout";

.Host {
  @include layout-fill;
  @include layout-vertical;

  backdrop-filter: blur(0px);
  background: transparent;
  display: none;
  position: fixed;
  transition: backdrop-filter 300ms, background 300ms;
  z-index: 1000;
}

.Host_Full {
  .Empty {
    height: 0%;
  }

  .Modal {
    border-radius: 0;
    height: 100%;
  }
}

.Host_Visible {
  display: flex;
}

.Host_Open {
  backdrop-filter: blur(3px);
  background: rgba(#000, 0.15);

  .Modal {
    transform: translateY(0%);
  }
}

.Empty {
  height: 100px;
  flex-grow: 1;
}

.Modal {
  background: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  flex-shrink: 0;
  transform: translateY(100%);
  transition: transform 300ms;
}
</style>
