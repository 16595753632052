<template>
  <div :class="$style.Host">
    <template v-for="tag of tags" :key="tag.id">
      <div :class="$style.TagSpacer">
        <Tag
          :label="tag.metadata.label"
          :color="tag.metadata.color"
          :controls="controls"
          @remove="$emit('remove', { tagId: tag.id })"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Tag from "@/components/Tag";

import store from "@/store";

export default {
  components: { Tag },

  props: {
    tagIds: {
      type: Array,
      default: () => [],
    },

    controls: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    tags() {
      return this.tagIds
        .map((id) => store.state.tags[id])
        .filter((tag) => !!tag);
    },
  },

  emits: ["remove"],
};
</script>

<style module lang="scss">
@import "@/styles/layout";

.Host {
}

.TagSpacer {
  display: inline-block;
  padding: 2px;
}
</style>
