import { jsonDateParser } from "json-date-parser";

import TagsMigrations from "@/storage/migrations/tags";
import TasksMigrations from "@/storage/migrations/tasks";

const Migrations = {
  tags: TagsMigrations,
  tasks: TasksMigrations,
};

export function saveDatabase(name, data) {
  // It's safe to assume that it's the latest version because we migrate to the
  // latest version every time we load a database.
  const migrations = Migrations[name];
  const latestVersion = migrations[migrations.length - 1];

  window.localStorage[name] = JSON.stringify({
    version: latestVersion.tag,
    data,
  });
}

export function loadDatabase(name) {
  const serializedDatabase = window.localStorage[name];
  if (!serializedDatabase) {
    const migrations = Migrations[name];
    const latestVersion = migrations[migrations.length - 1];
    return latestVersion.empty;
  }

  const { version, data } = JSON.parse(serializedDatabase, jsonDateParser);

  // Apply migrations if available.
  const migratedData = migrateData(name, version, data);
  if (migratedData) {
    saveDatabase(name, migratedData);
    return migratedData;
  }

  return data;
}

function migrateData(name, currentVersion, data) {
  const migrations = Migrations[name];

  const currentVersionIndex = migrations.findIndex(
    ({ tag }) => tag == currentVersion
  );

  if (currentVersionIndex == migrations.length - 1) {
    // No migrations are necessary.
    return null;
  }

  // Walk through all future versions applying migrations.
  let newData = data;
  for (let i = currentVersionIndex + 1; i < migrations.length; ++i) {
    const version = migrations[i];
    const previousVersionTag = migrations[i - 1].tag;
    console.log(
      `Migrating database#${previousVersionTag} to database#${version.tag}`
    );
    newData = version.migration(newData);
  }

  return newData;
}
