<template>
  <div :class="$style.Host" :style="`background: ${color}`">
    <div :class="$style.Label">{{ label }}</div>
    <template v-if="controls">
      <div :class="$style.Remove" @click="$emit('remove')" />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },

    color: {
      type: String,
      default: "#FFF",
    },

    controls: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["remove"],
};
</script>

<style module lang="scss">
@import "@/styles/layout";

.Host {
  @include layout-horizontal;

  background: #fff;
  border-radius: 4px;
  display: inline-flex;
}

.Label {
  font-size: 14px;
  padding: 0px 6px;
  line-height: 22px;
}

.Remove {
  background: rgba(0, 0, 0, 0.1);
  display: block;
  width: 22px;

  &::before {
    content: "x";
    display: block;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }
}
</style>
