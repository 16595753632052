import * as deepcopy from "deepcopy";

export default [
  // Version 0.0.0 did not use migration schema.
  {
    tag: "0.1.0",
    empty: {},
    migration(previousVersion) {
      const database = {};
      for (const [id, task] of Object.entries(previousVersion)) {
        database[id] = {
          id,
          metadata: {
            label: task.label,
          },
          scheduling: {
            periodic: {
              period: task.period,
              nextDueDate: task.nextDueDate,
            },
          },
          history: {
            lastOccurence: task.lastOccurence,
          },
        };
      }
      return database;
    },
  },
  {
    tag: "0.1.1",
    empty: {},
    migration(previousVersion) {
      const database = {};
      for (const [id, task] of Object.entries(previousVersion)) {
        database[id] = deepcopy(task);
        database[id].metadata.dateCreated = new Date();
      }
      return database;
    },
  },
  {
    tag: "0.1.2",
    empty: {},
    migration(previousVersion) {
      const database = {};
      for (const [id, task] of Object.entries(previousVersion)) {
        database[id] = deepcopy(task);
        database[id].metadata.tags = [];
      }
      return database;
    },
  },
  {
    tag: "0.1.3",
    empty: {},
    migration(previousVersion) {
      const database = {};
      for (const [id, task] of Object.entries(previousVersion)) {
        database[id] = deepcopy(task);
        database[id].metadata.title = database[id].metadata.label;
        delete database[id].metadata.label;
      }
      return database;
    },
  },
  {
    tag: "0.1.4",
    empty: {},
    migration(previousVersion) {
      const database = {};
      for (const [id, task] of Object.entries(previousVersion)) {
        database[id] = deepcopy(task);
        if (database[id].scheduling.periodic) {
          database[id].scheduling = {
            type: "periodic",
            ...database[id].scheduling.periodic,
          };
          delete database[id].scheduling.nextDueDate;
        } else if (database[id].scheduling.fixed) {
          database[id].scheduling = {
            type: "fixed",
            ...database[id].scheduling.fixed,
          };
        }
      }
      return database;
    },
  },
];
