import { createApp } from "vue";

import App from "@/App.vue";
import router from "@/router";
import store from "@/store";

import "@/registerServiceWorker";

if (process.env.NODE_ENV != "production") {
  window.__debug__ = {
    store,
  };
}

createApp(App).use(store).use(router).mount("#app");

store.dispatch("updateNowEvery", { milliseconds: 1000 });
