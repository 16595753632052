<template>
  <div :class="$style.Host" :style="`background-color: ${color_};`">
    <div :class="$style.Content">
      <slot />
    </div>
  </div>
</template>

<script>
import gradient from "gradient-color";

const kColorGradient = gradient(["#11da00", "#ffca00", "#ff0000"], 10);

export default {
  props: {
    score: {
      type: Number,
      default: 0,
    },

    gray: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    color_() {
      if (this.gray) {
        return "rgb(160, 160, 160)";
      }

      return kColorGradient[
        Math.floor(
          Math.min(Math.max(0, this.score), 1) * (kColorGradient.length - 1)
        )
      ];
    },
  },
};
</script>

<style module lang="scss">
.Host {
  border-radius: 16px;
  overflow: hidden;
  padding: 4px;
  min-height: 32px;
}

.Content {
  background: #fff;
  border-radius: 12px;
  min-height: 32px;
  overflow: hidden;
}
</style>
